import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"

const Iletisim = () => (
  <Layout>
    <SEO title="İletişim" />

    <PageHeader title="İletişim" />

    <div className="container mx-auto h-100 py-4">
      <div className="main py-2">
        <article className="grid grid-cols-1 md:grid-cols-2 text-gray-700 mb-5">
          <div className="col-span-1 text-center py-4 flex items-center">
            <table className="mx-auto w-5/6 text-sm md:text-md lg:text-lg  table-auto">
              <tbody>
                <tr>
                  <td className="w-1/4 py-2 font-bold text-left">Firma</td>
                  <td className="w-3/4 text-left">
                    Mrc Pamuklu Dokuma San. ve Tic. A. Ş.
                  </td>
                </tr>
                <tr>
                  <td className="w-1/4 py-2 font-bold text-left">Eposta</td>
                  <td className="w-3/4 text-left">
                    <a href="mailto:bilgi@mrctekstil.com">
                      bilgi@mrctekstil.com
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="w-1/4 py-2 font-bold text-left">Telefon</td>
                  <td className="w-3/4 text-left">0 258 3718563</td>
                </tr>
                <tr>
                  <td className="w-1/4 py-2 font-bold text-left">Faks</td>
                  <td className="w-3/4 text-left">0 258 3716332</td>
                </tr>
                <tr>
                  <td className="w-1/4 py-2 font-bold text-left ">Adres</td>
                  <td className="w-3/4 text-left">
                    Akçeşme Mh. Bozburun Cd. No:14 Gümüşler 20020 DENİZLİ /
                    TÜRKİYE
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-span-1 text-center py-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.7335515628897!2d29.06054721556192!3d37.81970957975105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c73e4f46f95663%3A0x66316061206a5abc!2sMrc%20Pamuklu%20Dokuma!5e0!3m2!1str!2str!4v1603874985923!5m2!1str!2str"
              width="600"
              height="450"
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
              className="mx-auto bg-gray-200 border"
            ></iframe>
          </div>
        </article>
      </div>
    </div>
  </Layout>
)

export default Iletisim
